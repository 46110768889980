import { Button } from "antd";
import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px #000000;
  transition: all 1s;
  @media screen and (max-width: 540px) {
    max-width: 530px;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-family: NanumSquareOTF;
  font-size: 12px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: -0.05em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;

  &:active,
  :focus {
    outline: none;
  }

  &::placeholder {
    font-family: "SpoqaHanSansNeo-Light";
    font-size: 14px;
    font-weight: 300;
    color: #959595;
  }
`;

export const SearchButton = styled(Button)`
  width: auto;
  height: auto;

  &:active,
  :hover,
  :focus {
    background-color: transparent;
  }

  span {
    font-size: 18px;
    color: #6d6d6d;
  }
`;
