import styled from "styled-components";

export const CardContent = styled.div`
  min-width: 617px;
  overflow: hidden;
  border: 1px solid #d5d5d5;
  border-radius: 15px;
  padding: 1% 1%;

  @media (max-width: 567px) {
    min-width: 88%;
  }

  .title {
    display: flex;
    gap: 16px;
    align-items: center;
    text-align: center;
    border-bottom: 0.5px solid;

    .num {
      background: #653cd5;
      width: 41px;
      height: 22px;
      gap: 0px;
      border-radius: 5px;
      opacity: 0px;

      span {
        font-family: NanumSquareOTF;
        font-size: 13px;
        font-weight: 800;
        letter-spacing: -0.05em;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #ffffff;

        @media (max-width: 567px) {
          font-size: 10.07px;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      .author {
        font-family: NanumSquareOTF;
        font-size: 20px;
        font-weight: 700;
        line-height: 47px;
        letter-spacing: -0.05em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        @media (max-width: 540px) {
          overflow: hidden;
        }

        @media (max-width: 567px) {
          font-size: 15px;
        }
      }

      .product {
        font-family: NanumSquareOTF;
        font-size: 17px;
        font-weight: 700;
        line-height: 47px;
        letter-spacing: -0.05em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        @media (max-width: 567px) {
          font-size: 13px;
          line-height: 36.39px;
        }
      }
    }
  }

  .content {
    font-size: 20px;
    color: #333;
    margin: 3% 0px 0px 0px;
    text-align: left;

    .saleprice {
      font-family: NanumSquareOTF;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: -0.05em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      @media (max-width: 567px) {
        font-size: 15px;
      }
    }

    .more {
      font-family: NanumSquareOTF;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: -0.05em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      @media (max-width: 567px) {
        font-size: 13px;
      }
    }

    .place {
      font-family: NanumSquareOTF;
      font-size: 15px;
      font-weight: 300;
      letter-spacing: -0.05em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      text-align: right;

      @media (max-width: 567px) {
        font-size: 10px;
      }
    }
  }
`;
