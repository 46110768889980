import { InputNumber } from "antd";
import React from "react";
import * as S from "./style";

interface Props {
  disabled?: boolean;
  onChange?: (e: number) => void;
  handleUp?: () => void;
  handleDown?: () => void;
  max?: number;
  value?: number;
}

function InputNumberNew({
  disabled,
  onChange,
  max = 0,
  handleUp,
  handleDown,
  value = 1,
}: // value = disabled ? 0 : 1,
Props) {
  return (
    <S.Container>
      <div
        className="btn-count"
        onClick={() => {
          if (value > 1 && !disabled) {
            handleDown?.();
          }
        }}
      >
        -
      </div>
      <InputNumber
        min={1}
        max={max}
        onChange={(e: any) => onChange?.(e || 0)}
        readOnly={disabled}
        value={value}
      />
      <div
        className="btn-count"
        onClick={() => {
          if (!disabled) {
            handleUp?.();
          }
        }}
      >
        +
      </div>
    </S.Container>
  );
}

export default InputNumberNew;
