import moment from "moment";
import React from "react";

/** Utils */

/** Local Style */
import { useTranslation } from "react-i18next";
import moneyConvertLang from "utils/moneyConvertLang";
import * as S from "./style";

interface Props {
  noNumber: any;
  data: any;
  gotToDetail?: () => void;
}

const ArtTop10CardItem = ({ noNumber, data, gotToDetail }: Props) => {
  const { t } = useTranslation();
  return (
    <S.CardContent onClick={gotToDetail}>
      <div className="title">
        <div className="num">
          <span>{noNumber ?? 1}위</span>
        </div>
        <div className="right">
          <div className="author">{data?.authorName}</div>
          <div className="product">{data?.productName}</div>
        </div>
      </div>
      <div className="content">
        <div className="saleprice">
          낙찰가 : {moneyConvertLang(data?.price)}
        </div>
        <span className="more">
          {t("TOP10_DATE")}:
          {data?.tradeDay
            ? moment(data?.tradeDay ?? "").format("YYYY.MM.DD")
            : ""}
        </span>
        <div className="place">
          {t("TOP10_HOUSE")}:
          {data?.exhibitionDestination?.length > 35
            ? `${data?.exhibitionDestination.substring(0, 35)}...`
            : data?.exhibitionDestination}
        </div>
      </div>
    </S.CardContent>
  );
};

export default ArtTop10CardItem;
