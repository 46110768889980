import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  &.footer-none {
    display: none;
  }
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  width:  100%; 
  padding: 0 15px;
  margin: 0 auto;

  @media (min-width: 1351px) {
    max-width: 1350px;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: 1024px) and (max-width: 1350px) 
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    max-width: 1024px;
  }

  @media (max-width: 1024px) {

  }

  .step {
    width: 40%;
    padding: 30px 0 30px;

    @media screen and (max-width: 768px) {
      padding: 0 40px;
      margin-top: 46px;
      &:first-child {
        padding: 0 40px;
      }
      &:last-child {
        padding: 0 40px;
      }
    }
    @media screen and (max-width: 540px) {
      padding: 0 25px;
      &:first-child {
        padding: 0 25px;
      }
      &:last-child {
        padding: 0 25px;
      }
    }
    @media screen and (max-width: 360px) {
      padding: 0 2px 0 15px;
      &:first-child {
        padding: 0 2px 0 15px;
      }
      &:last-child {
        padding: 0 2px 0 15px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    display: block;
    padding: 25px 0 60px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  gap: 9px;

  .item {
    display: flex;
    flex-direction;
    gap: 5px;
     font-family: NanumSquareOTF;
    font-size: 12px;
    line-height: 1.3;
    letter-spacing: -0.05em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    .key {
      font-weight: 700;
    }

    .value {
      font-weight: 400;
    }
  }
`;

export const SubBox = styled.div`
  margin-top: 5%;

  .title {
    font-family: NanumSquareOTF;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: -0.05em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 8px;
  }

  .item {
    display: flex;
    flex-wrap: wrap;
    gap: 9px;

    .key {
      font-family: NanumSquareOTF;
      font-size: 12px;
      font-weight: 700;
      line-height: 1.3;
      letter-spacing: -0.05em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

    .value {
      font-family: NanumSquareOTF;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: -0.05em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
`;

export const Title = styled.div`
  font-family: NanumSquareOTF;
  font-size: 19px;
  font-weight: 700;
  line-height: 9px;
  letter-spacing: -0.05em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 3%;
`;

export const TextModified = styled.div`
  font-family: "SpoqaHanSansNeo-Bold";
  font-size: 18px;
  font-weight: 600;
  line-height: 1.22;
  letter-spacing: -0.9px;
  color: #6d6d6d;
  margin: 43px 0 9px;
  > span {
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 1.27;
    letter-spacing: -0.75px;
  }
  @media screen and (max-width: 360px) {
    padding-right: 90px;
  }
  > div {
    cursor: pointer;
  }
`;

export const TextMin = styled.div`
  font-family: NanumSquareOTF;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: -0.05em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ContainerTerm = styled.div`
  width: 100%;
  padding: 0 15px;
  background: #c7c7c7;
  margin: 0 auto;

  @media (min-width: 1025px) {
    padding: 15px;
  }

  @media (max-width: 567px) {
    padding: 5% 5%;
  }
  .container-term-list {
    width: 100%;

    @media (min-width: 1351px) {
      max-width: 1350px;
      margin: 0 auto;
      width: 100%;
      padding: 0 15px;
    }

    @media (min-width: 1024px) and (max-width: 1350px) {
      margin: 0 auto;
      max-width: 1024px;
      padding: 0 15px;
    }
  }
  .item {
    display: flex;
    flex-direction: row;
    gap: 9px 18px;

    .term {
      display: flex;
      flex-direction: row;
      gap: 9px 18px;
    }
  }
`;
