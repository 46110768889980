import { mainAxios } from "libs/axios";

export const getInfoShinhanApi = () => {
  return mainAxios.request({
    methodType: "GET",
    url: `/shinhan/my-balance`,
    requiresToken: true,
    notError: true,
    getError: true,
  });
};

export const unlinkShinhanApi = (userId: string) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/shinhan/unlink`,
    data: { id: userId },
    requiresToken: true,
    notError: true,
    getError: true,
  });
};
