import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  &.footer-none {
    display: none;
  }
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 15px;

  @media (min-width: 768px) and (max-width: 1024px) {
    flex-direction: unset;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .step {
    @media (min-width: 768px) and (max-width: 1024px) {
      width: 40%;
      padding: 85px 0 60px;
    }

    @media (max-width: 767px) {
      width: 100%;
      padding: 30px 0 15px;
    }
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  gap: 9px;

  .key {
    font-family: NanumSquareOTF;
    font-size: 12px;
    font-weight: 700;
    line-height: 9px;
    letter-spacing: -0.05em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .value {
    font-family: NanumSquareOTF;
    font-size: 12px;
    font-weight: 400;
    line-height: 9px;
    letter-spacing: -0.05em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
`;

export const SubBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 5%;

  title {
    font-family: NanumSquareOTF;
    font-size: 12px;
    font-weight: 700;
    line-height: 9px;
    letter-spacing: -0.05em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .item {
    display: flex;
    flex-direction: row;
    gap: 5px;

    .key {
      font-family: NanumSquareOTF;
      font-size: 12px;
      font-weight: 700;
      line-height: 9px;
      letter-spacing: -0.05em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

    .value {
      font-family: NanumSquareOTF;
      font-size: 12px;
      font-weight: 400;
      line-height: 9px;
      letter-spacing: -0.05em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
`;

export const Title = styled.div`
  font-family: NanumSquareOTF;
  font-size: 19px;
  font-weight: 700;
  line-height: 9px;
  letter-spacing: -0.05em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 3%;
`;

export const TextModified = styled.div`
  font-family: "SpoqaHanSansNeo-Bold";
  font-size: 18px;
  font-weight: 600;
  line-height: 1.22;
  letter-spacing: -0.9px;
  color: #6d6d6d;
  margin: 43px 0 9px;
  > span {
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 1.27;
    letter-spacing: -0.75px;
  }
  @media screen and (max-width: 360px) {
    padding-right: 90px;
  }
  > div {
    cursor: pointer;
  }
`;

export const TextMin = styled.div`
  font-family: NanumSquareOTF;
  font-size: 10px;
  font-weight: 400;
  line-height: 7.34px;
  letter-spacing: -0.05em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  @media (min-width: 1441px) {
    margin-left: 397px;
    margin-right: 397px;
  }
`;

export const ContainerTerm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  width: 100%;
  padding: 0 15px;
  background: #c7c7c7;
  font-family: NanumSquareOTF;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: -0.05em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  @media (max-width: 567px) or (max-width: 1024px) {
    padding: 20px 15px;
  }

  .item {
    display: flex;
    flex-direction: row;
    gap: 9px;

    @media (max-width: 567px) {
      gap: 9px;
      flex-wrap: wrap;
    }

    .term {
      display: flex;
      flex-direction: row;
      gap: 9px;
    }
  }
`;
