import { Modal } from "antd";
import React from "react";
import * as S from "./style";
// import img from "assets/images/popup.png";
interface PopupProps {
  visible: boolean;
  setVisible: () => void;
  title?: string;
  subTitle?: string;
}

const Popup: React.FC<PopupProps> = ({
  visible,
  setVisible,
  title,
  subTitle,
}) => {
  return (
    <>
      <Modal
        bodyStyle={{ height: 250 }}
        visible={visible}
        width={400}
        closable={false}
        footer={null}
        onCancel={() => setVisible()}
      >
        <S.Container>
          <h2>{title ?? "제휴신청이"}</h2>
          <h2>{subTitle ?? "완료되었습니다."}</h2>
          {/* <S.Image>
            <img src={img} alt="" />
          </S.Image> */}
        </S.Container>
      </Modal>
    </>
  );
};

export default Popup;
