export enum UrlInternal {
  API_LOGIN_URL = "/api/v1/login",
  API_SIGNUP_URL = "/api/v1/signup",
  // Material Group Page
  MATERIAL = "/material/*",
  MATERIAL_PRESS = "/material/press",
  MATERIAL_PRESS_DETAIL = "/material/press/:id",
  MATERIAL_NEWSPAPER = "/material/newspaper",
  MATERIAL_NEWSPAPER_DETAIL = "/material/newspaper/:id",
  MATERIAL_VIDEO = "/material/videos",

  // Group Sale Group Page
  GROUP_SALE = "/group-sale/*",
  GROUP_SALE_GENERAL_PURCHASE_SCHEDULE = "/group-sale/general-purchase-schedule",
  GROUP_SALE_COMMING = "/group-sale/sale-comming",
  GROUP_SALE_COMMING_DETAIL = "/group-sale/sale-comming/:id",
  // GROUP_SALE_COMMING_DETAIL_2 = "/group-sale/sale-comming-2",
  GROUP_SALE_ON = "/group-sale/on-sale",
  // GROUP_SALE_ON_DETAIL = "/group-sale/on-sale/:id",
  GROUP_SALE_ON_ORDER = "/group-sale/on-sale/order/detail",
  GROUP_SALE_ON_ORDER_DETAIL = "/group-sale/on-sale/order/detail/:id",
  // GROUP_SALE_ON_HAVE_SLOT = "/group-sale/on-sale/order/have-slot",
  GROUP_SALE_ON_ORDER_CONFIRM = "/group-sale/on-sale/order/confirm",
  GROUP_SALE_ON_ORDER_CONFIRM2 = "/group-sale/on-sale/order/confirm-2",
  // GROUP_SALE_ON_NOT_SLOT = "/group-sale/on-sale/order/not-slot",
  GROUP_SALE_END = "/group-sale/end-sale",
  GROUP_SALE_END_ON_DETAIL = "/group-sale/end-sale/:id",

  // Register
  REGISTER_AGREE_TERMS = "/register/agree-terms",
  REGISTER_PERSONAL_INFO = "/register/personal-info",
  REGISTER_COMPANY = "/register/company",
  FIND_ID_PASSWORD = "/find-id-password",

  // Other
  INTRODUCE = "/introduce",
  PARTNERSHIP = "/partnership",
  LOGIN = "/login",
  HOME = "/",
  SEARCH = "/search_results",

  LOGIN_NAVER_SNS = "/handleNaverCb",
  LOGIN_KAKAO_SNS = "/handleKakao",
  LOGIN_GOOGLE_SNS = "/handleGG",
  LOGIN_FACEBOOK_SNS = "/handleFb",
  SNS_KAKAO_NAVER = "/handleSNS-new",

  // ===== CustomService
  CUSTOMER_SERVICE = "/customer-service/*",
  ANNOUCEMENT = "/customer-service/annoucement",
  ANNOUCEMENT_DETAIL = "/customer-service/annoucement-detail/:id",
  ROUTES_ANNOUCEMENT_DETAIL = "/customer-service/annoucement-detail",
  QUESTION = "/customer-service/question-faq",
  APPOINTMENT = "/customer-service/appointment",
  REGISTER_APPOINTMENT = "/customer-service/appointment/register-appointment",
  GROUP_SALE_GUIDE_CUSTOMER_SERVICE_STEP = "/customer-service/sale-guide",
  GROUP_SALE_GUIDE_LIST = "/customer-service/sale-guide/list",
  GROUP_SALE_GUIDE_DETAIL = "/customer-service/sale-guide/list/detail/:id",
  ROUTES_GROUP_SALE_GUIDE_DETAIL = "/customer-service/sale-guide/list/detail",
  PUBLIC_NOTICE = "/customer-service/public-notice",
  PUBLIC_NOTICE_DETAIL = "/customer-service/public-notice/:id",
  ROUTES_PUBLIC_NOTICE_DETAIL = "/customer-service/public-notice",
  // ========================

  // INSIGHT
  INSIGHT = "/insight/*",
  NEWS_LETTER_LIST = "/insight/news-letter/list",
  ART_INSIGHT = "/insight/art-insight",
  ART_INSIGHT_DETAIL = "/insight/art-insight/:id",
  NEWS_LETTER = "/insight/news-letter/*",
  NEWS_LETTER_DETAIL = "/insight/news-letter/list/detail/:id",
  ROUTES_NEWS_LETTER_DETAIL = "/insight/news-letter/list/detail",
  TEMP = "/insight/temp",
  TEMP_DETAIL = "/insight/temp/detail",
  TEMP_DETAIL_ID = "/insight/temp/detail/:id",

  // Ownership
  OWNERSHIP = "/owner-ship",
  PURCHASE_SUMMARY = "/purchase-summary",

  // My page
  MYPAGE = "/mypage/*",
  MYPAGE_MYACCOUNT = "/mypage/my-account",
  MYPAGE_BUY_TOGETHER_HISTORY = "/mypage/together-history",
  MYPAGE_BUY_TOGETHER_HISTORY_INCLUDES = "/mypage/together-history/detail",
  MYPAGE_DETAIL_BUY_TOGETHER_HISTORY = "/mypage/together-history/detail/:id",
  MYPAGE_POINT_HISTORY = "/mypage/point-history",
  MYPAGE_FAQ_HISTORY = "/mypage/1:1-inquiry",
  MYPAGE_FAQ_HISTORY_CREATE = "/mypage/1:1-inquiry/create",
  MYPAGE_FAQ_HISTORY_EDIT = "/mypage/1:1-inquiry/edit",
  MYPAGE_ART_SHOPPING_HISTORY = "/mypage/art-shopping-history",
  MYPAGE_ART_SHOPPING_HISTORY_INCLUDES = "/mypage/art-shopping-history/detail",
  MYPAGE_DETAIL_ART_SHOPPING_HISTORY = "/mypage/art-shopping-history/detail/:id",
  MYPAGE_APPOINTMENT_HISTORY = "/mypage/appointment-history",
  MYPAGE_COUPON = "/mypage/coupon",

  // Art market
  ART_MARKET = "/art-maket/*",
  ART_MARKET_PRODUCT_ALL = "/art-maket/product-all",
  ART_MARKET_PRODUCT_ALL_INCLUDES = "/art-maket/product-all/detail",
  ART_MARKET_PRODUCT_ALL_DETAIL = "/art-maket/product-all/detail/:id",
  ART_MARKET_PRODUCT_ALL_ORDER_INFOMATION = "/art-maket/product-all_order-information",
  ART_MARKET_PRODUCT_ALL_ORDER_INFOMATION_STEP2 = "/art-maket/product-all_order-information-2",

  ART_MARKET_PRODUCT_LIST = "/art-maket/product-list",
  ART_MARKET_PRODUCT_LIST_INCLUDES = "/art-maket/product-list/detail",
  ART_MARKET_PRODUCT_LIST_DETAIL = "/art-maket/product-list/detail/:id",
  ART_MARKET_PRODUCT_ORDER_INFOMATION = "/art-maket/product-list_order-information",
  ART_MARKET_PRODUCT_ORDER_INFOMATION_STEP2 = "/art-maket/product-list_order-information-2",
  ART_MARKET_PRODUCT_ORDER_DRAW = "/art-maket/draw/product-list_order-information",

  ART_MARKET_WON_LIST = "/art-maket/won-list",
  ART_MARKET_WON_LIST_INCLUDES = "/art-maket/won-list/detail",
  ART_MARKET_WON_LIST_DETAIL = "/art-maket/won-list/detail/:id",
  ART_MARKET_WON_ORDER_INFOMATION = "/art-maket/won-list_order-information",
  ART_MARKET_WON_ORDER_INFOMATION_STEP2 = "/art-maket/won-list_order-information-2",

  ART_MARKET_WOOD_BLOCK_PRINTS_LIST = "/art-maket/wood-block-prints",
  ART_MARKET_WOOD_BLOCK_PRINTS_LIST_INCLUDES = "/art-maket/wood-block-prints/detail",
  ART_MARKET_WOOD_BLOCK_PRINTS_LIST_DETAIL = "/art-maket/wood-block-prints/detail/:id",
  ART_MARKET_WOOD_BLOCK_PRINTS_ORDER_INFOMATION = "/art-maket/wood-block-prints_order-information",
  ART_MARKET_WOOD_BLOCK_PRINTS_ORDER_INFOMATION_STEP2 = "/art-maket/wood-block-prints_order-information-2",

  ART_MARKET_PRINTS = "/art-maket/prints",
  ART_MARKET_PRINTS_INCLUDES = "/art-maket/prints/detail",
  ART_MARKET_PRINTS_DETAIL = "/art-maket/prints/detail/:id",
  ART_MARKET_PRINTS_ORDER_INFOMATION = "/art-maket/prints_order-information",
  ART_MARKET_PRINTS_ORDER_INFOMATION_STEP2 = "/art-maket/prints_order-information-2",

  ART_MARKET_INCLUDES_ORDER_INFOMATION = "_order-information",
  ART_MARKET_INCLUDES_ORDER_INFOMATION_STEP2 = "_order-information-2",

  ART_MARKET_GOODS = "/art-maket/goods",
  ART_MARKET_GOODS_INCLUDES = "/art-maket/goods/detail",
  ART_MARKET_GOODS_DETAIL = "/art-maket/goods/detail/:id",

  //art market draw
  ART_MARKET_DRAW = "/art-maket/draw",
  ART_MARKET_DRAW_DETAIL = "/art-maket/draw/:id",
  ART_MARKET_DRAW_INCLUDES = "/art-maket/draw",

  // Link Page
  ART_LINK_AGREEMENT = "/art/site_agreement",
  ART_LINK_BUYER = "/art/site_buyer",
  ART_LINK_SELLER = "/art/site_seller",
  ART_LINK_PRIVACY = "/art/site_privacy",
  ART_LINK_PRODUCT = "/art/site_product",
  ART_AGREE_TERM = "/art/:slug",

  //check pathname active header
  PATHNAME_MYPAGE = "/mypage",
  PATHNAME_GROUP_SALE = "/group-sale",
  PATHNAME_ART_MARKET = "/art-maket",
  PATHNAME_CUSTOM_SERVICE = "/customer-service",

  //nice api
  NICE_VERIFY_RESULT = "/verify-result",
  NICE_FORM = "/nice-form",

  //COLLECT_USER_INFORMATION

  COLLECT_USER_INFORMATION = "/gp/collect-data/:id",

  MAINTENANCE = "/maintenance",

  VOTE_HOLDING_PRODUCT = "/vote-holding/:id",
}
