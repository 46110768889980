import React from "react";
import * as S from "./style";

interface Props {
  linkEmbed?: string,
  width?:any,
  height?:any
}

const Video = (props: Props) => {
  const { linkEmbed="https://www.youtube.com/embed/MlBvrAvzX8E", width = "1242", height="700" } = props;
  return (
    <S.Container className="video-component">
      <iframe width={width} height={height} src={linkEmbed} title="YouTube video player" frameBorder={"0"} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </S.Container>
  );
}

export default Video;
