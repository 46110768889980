import styled from "styled-components";

export const Container = styled.div`
  .ant-table-wrapper {
    .ant-table-cell {
      font-family: NanumSquareOTF;
      font-size: 17px;
      font-weight: 700;
      line-height: 47px;
      letter-spacing: -0.05em;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
    .ant-table-header {
      display: none;

      .ant-table-cell {
        font-family: NanumSquareOTF;
        font-size: 18px;
        &::before {
          content: none !important;
        }
      }
      .ant-table-thead > tr > th {
        border: none;
        background: #f3f3f3;
        padding: 27px 8px;
        @media screen and (max-width: 768px) {
          padding: 27px 5px;
        }
      }
    }
    .ant-table-body {
      cursor: pointer;
      .ant-table-tbody > tr > td {
        padding: 27px 10px;
      }

      .ant-table-tbody > tr > .text-number {
        color: #8e8e8e;
        font-weight: 800;
      }
    }
  }
  /** pagination */
  .ant-pagination {
    margin-top: 42px;
    align-items: center;
    gap: 0px;
    /* @media screen and (max-width: 567px) {
      gap: 0;
    } */
    .ant-pagination-next,
    .ant-pagination-prev {
      svg {
        margin-top: 9px;
      }
    }

    .ant-pagination-item {
      border: none;
      /* font-family: "SpoqaHanSansNeo-Regular"; */
      /* margin: 0 -13.5px; */
      a {
        font-size: 15px;
        color: #6d6d6d;
        font-family: "SpoqaHanSansNeo-Regular";
        margin-top: 0.8px;
        :hover {
          color: #1890ff;
        }
      }
    }
    .ant-pagination-item-active {
      order: solid 1px #653cd5 !important;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      color: #0d0d0d;
      line-height: 31px;
      a {
        color: #0d0d0d;
        font-family: "SpoqaHanSansNeo-Medium";
        margin-top: -0.2px;
        padding: 0;
      }
    }
    .ant-pagination-item-ellipsis {
      top: 30%;
    }
    .ant-pagination-options {
      display: none;
    }
  }
`;
