import styled from "styled-components";

export const Container = styled.div`
  .slick-list {
    overflow: unset;
    position: relative;
    &:before {
      content: "";
      width: 261px;
      height: 100%;
      position: absolute;
      /* background-color: #faf6f2; */ //comment tạm
      background-color: #ffffff;
      z-index: 99999;
      left: -261px;
      top: -3px;
    }

    .slick-slide,
    .slick-current {
      max-width: 400px !important;
      width: min-content !important;
      margin: 0px 120px 0px 0px;
    }

    @media screen and (max-width: 768px) {
      .slick-slide,
      .slick-current {
        width: 280px !important;
        margin: 0px 47px 0px 0px;
      }
    }
  }
`;

export const WrappSlide = styled.div`
  overflow: auto hidden;
`;

export const TitleWp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 75px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    .sub-title {
      font-size: 32px;
    }
  }
`;

export const ArrowsTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const ArrowText = styled.span`
  font-family: "SpoqaHanSansNeo-Regular";
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.5px;
  text-align: center;
  color: #000000;
  padding-right: 20px;
  cursor: pointer;

  &:hover {
    color: #ad9a6d;
  }
`;

export const CardWp = styled.div`
  display: flex !important;
  justify-content: center;
  /* padding-right: 120px; */
`;

export const CardMeta = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  /* margin-bottom: 40px; */
`;

export const CardContent = styled.div`
  img {
    max-width: 400px;
    cursor: pointer;
    margin: auto;
  }

  @media screen and (max-width: 768px) {
    img {
      max-width: 280px;
    }
  }
`;

export const CardTitle = styled.span`
  font-family: "SpoqaHanSansNeo-Light";
  font-size: 32px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.8px;
  text-align: center;
  color: #111111;
  margin-bottom: 14px;

  @media screen and (max-width: 768px) {
    font-size: 26px;
  }
`;

export const CardPrice = styled.span`
  font-family: "SpoqaHanSansNeo-Regular";
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.5px;
  text-align: center;
  color: #ad9a6d;

  .old-price {
    color: #a7a7a7;
    font-size: 16px;
    text-decoration: line-through;
  }
`;

export const BtnGroup = styled.div`
  button {
    span {
      font-size: 20px;
      color: #000000;
      &:hover {
        color: #ad9a6d;
      }
    }
  }
`;
