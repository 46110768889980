import styled, { css } from "styled-components";

export const Wrapper = styled.div``;

export const Container = styled.div`
  border-bottom: 0.5px solid #535353b0;
  display: flex;
  align-items: center;
  gap: 50px;
  overflow: auto;
  height: 114px;
  justify-content: center;
  margin: 0 auto;

  @media (min-width: 360px) and (max-width: 767px) {
    width: 100%;
    gap: 5px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
  }

  @media (min-width: 1025px) and (max-width: 1350px) {
    max-width: 1024px;
  }

  @media (min-width: 1351px) {
    max-width: 1350px;
  }
`;

export const Tab = styled.div<{ active?: boolean }>`
  color: #0d0d0d;
  cursor: pointer;
  white-space: nowrap;
  padding: 0px 30px;
  font-family: NanumSquareOTF;
  font-size: 15px;
  letter-spacing: -0.05em;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  @media (min-width: 360px) and (max-width: 1024px) {
    padding: 0px 10px;
  }

  ${(props) => {
    if (props.active) {
      return css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 23px;
        border: solid 1px #653cd5;
        background-color: #fff;
        height: 45px;
        padding: 0px 30px;
        color: #653cd5;
        font-weight: 800;
      `;
    }
  }}

  &:hover {
    color: #653cd5;
  }
`;
