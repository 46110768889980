import { Checkbox } from "antd";
import styled, { css } from "styled-components";

export const CheckBox = styled(Checkbox)<{ colorText: any; active: boolean }>`
  &.ant-checkbox-wrapper {
    color: #929292;
    line-height: 1.27;
    letter-spacing: 0.38px;
    font-family: "SpoqaHanSansNeo-Medium";
    font-size: 15px;
    font-weight: 500;
    align-items: baseline;

    ${(props) => {
      if (props.active) {
        return css`
          color: #0d0d0d;
          font-family: "SpoqaHanSansNeo-Medium";
          font-weight: 500;
        `;
      }
    }}

    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      border-radius: unset;
      border: solid 1px #c6cdc7;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #653cd5;
      border-color: transparent;
      &::after {
        left: 30%;
      }
    }
    .ant-checkbox + span {
      padding-left: 10px;
      padding-right: 0;
      font-size: 15px;
      color: ${({ colorText }) => colorText};
      align-self: end;
    }
  }
`;
