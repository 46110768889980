import { Select } from "antd";
import styled from "styled-components";

export const Selects = styled(Select)`
  .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid #000000 !important;
    box-shadow: unset !important;
    padding: 0 !important;
    color: #000000;
    min-width: 80px;
    margin-right: 18px;
    font-family: NanumSquareOTF;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: -0.05em;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    border-radius: 0px !important;
  }
  svg path {
    fill: #0d0d0d;
  }
`;
