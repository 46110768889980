import { Result } from "antd";
import React from "react";
import notFound from "assets/images/notFound.png";

const ResultComponent = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      icon={<img src={notFound} alt="img" />}
    />
  );
};

export default ResultComponent;
