import React from "react";
import * as S from "./style";

interface Props {
  img?: any;
}

const MaterialCard = (props: Props) => {
  const { img } = props;
  return (
    <S.Container>
      <img src={img} alt="" />
    </S.Container>
  );
};

export default MaterialCard;
